import React, { useEffect, useState } from 'react'
import { Flex, Radio } from 'antd';

import Footer from '../../components/Reusable/js/Footer'
import axios from 'axios'
import toast from 'react-hot-toast'
import { NavLink } from 'react-router-dom'
import styles from "../Y20Placements/y20pla.module.css"
import Nav from '../../components/Reusable/js/Nav';

const Y20Placements = () => {

  const [companies, setcompanies] = useState([])
  const [display, setDisplay] = useState("Companies")
  const [students, setStudents] = useState([])

  const getCompanies = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/pages/placements/get-companies`)
      if (res.data.success) {
        setcompanies(res.data.result)
        // toast.success("Companies list obtained successfully!")
      }
      else {
        console.log("something went wrong")
        toast.error("Something went wrong!")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const GetStudents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/pages/placements/get-students`)
      if (res.data.success) {
        setStudents(res.data.result)
        // toast.success("students list obtained successfully!")
      }
      else {
        console.log("something went wrong")
        toast.error("Something went wrong!")

      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getCompanies();
  }, [])



  const Breadcrumb = () => {
    return (
      <nav aria-label="breadcrumb" style={{ margin: "0", padding: "0" }}>
        <ol className="breadcrumb m-2">
          {/* <li className="mr-2" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            <abbr title="Go back">
              <IoArrowBackCircle style={{ fontSize: '1.8rem' }} />
            </abbr>
          </li> */}
          <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
          <li className="breadcrumb-item"><NavLink to="">Y20 placements</NavLink></li>

          <li className="breadcrumb-item active" aria-current="page">{display}</li>
        </ol>
      </nav>
    );
  };


  const CompaniesList = () => {
    return (
      <>
        <h2 className='my-2'>Total companies: {companies.length}</h2>
        <div className={`${styles.deskplace}`}>
          {companies.map(company => (
            <div className="card" style={{ width: "16rem", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <div>
                <img src={company.imglink} alt="company pic" style={{ objectFit: "fill", width: "14.5rem", height: "10rem" }} />
                <div className={`${styles.rower} mt-3`}>
                  <h5 className='text-center'>{company.company}</h5>
                  <h6 className='m-1'><i class="fa-solid fa-location-dot mx-1"></i>{company.des}</h6>
                </div>
                <div className={`${styles.rower}`}>
                  <p style={{ margin: "1px" }}><i class="fa-solid fa-user mx-1"></i>{company.no} offers</p>
                  <p style={{ margin: "1px" }}><i class="mx-1 fa-solid fa-indian-rupee-sign"></i> {company.high} LPA</p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginTop: "auto" }}>
                <a href={company.link} style={{ width: "100%" }} target='_blank' rel='noreferrer' className='btn btn-sm btn-warning m-1'>Students</a>
                <a href={company.link} style={{ width: "100%" }} target='_blank' rel='noreferrer' className='btn btn-sm btn-primary m-1'>visit company</a>
              </div>
            </div>
          ))}
        </div>

        <div className={`${styles.mobpla}`}>

          <div style={{ margin: "2px" }}>
            <h2 className="text-center my-4">Company List</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Offers</th>
                  <th>Highest Package</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company, index) => (
                  <tr key={index}>
                    <td><a href={company.link}>{company.company}</a></td>
                    <td>{company.no}</td>
                    <td>{company.high}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


        </div>
      </>
    )
  }

  const StudentsList = () => {
    const totalCount = students.reduce((sum, student) => sum + Number(student.count), 0);
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="topheader" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <h4 className='mt-3'>Total students: {students.length}</h4>
            <h4 className='mt-3'>Total offers:{totalCount} </h4>
          </div>
          <div style={{ minHeight: "10vh", display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
            {
              students.map(student => (

                <div className="card" style={{ width: "18rem", }}>

                  <div className={`${styles.columner}`}>
                    <div className={`${styles.rower}`}>
                      <div className="m-1"><i class="fa-solid fa-user fa-3x"></i></div>
                      <div className={`${styles.columner}`}>
                        <div className={`${styles.columner}`}>
                          <h5>{student.regno.toUpperCase()}</h5>
                          <p>{student.name.split(' ')[0]} {student.name.split(' ')[1]}</p>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.rower} m-2`}>
                      <p><i class="fa-solid fa-building"></i> {student.count} offers</p>
                      <p><i class="fa-solid fa-indian-rupee-sign"></i> {student.sal} LPA</p>
                    </div>
                    <button className='btn btn-sm btn-warning' onClick={() => {
                      alert("Details not yet added!")
                    }}>Details</button>
                  </div>


                </div>


              ))
            }
          </div>
        </div>


      </>
    )
  }


  return (
    <>
      <Nav />
      <Breadcrumb />
      <hr />

      <div className="container" style={{ minHeight: "50vh" }}>
        <div className="container" style={{ minHeight: "40vh", margin: "20px" }}>

          <div className={`${styles.mobbtns} container text-center`}>
            <Flex vertical gap="middle">
              <Radio.Group defaultValue="a" size="large" className={`${styles.mobbtns}`}>
                <Radio.Button className={`mx-2 mb-2 ${styles.btnsize}`} style={{ fontSize: "1.7rem", color: display === "Companies" ? "blue" : "inherit" }} value="Companies" onClick={() => setDisplay("Companies")}>Companies</Radio.Button>
                <Radio.Button className={`mx-2 mb-2 ${styles.btnsize}`} style={{ fontSize: "1.7rem" }} value="Students" onClick={() => {
                  GetStudents()
                  setDisplay("Students")
                }}>Students</Radio.Button>
              </Radio.Group>
            </Flex>
          </div>
          <div className="container" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around" }}>
            {display === "Companies" ? <CompaniesList /> : <StudentsList />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Y20Placements