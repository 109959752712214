import React, { useState, useEffect } from 'react';
import Nav from '../../components/Reusable/js/Nav';
import Footer from '../../components/Reusable/js/Footer';
import { Carousel } from 'antd';
import styles from "../PlacementMaterials/PlacementMaterials.module.css"
import axios from 'axios';
import toast from 'react-hot-toast';
import Spinner from '../../components/Reusable/js/Spinner';


const PlacementMaterials = () => {

    const [sub, setsub] = useState("")
    const [display, setdisplay] = useState(false)
    const [materials, setMaterials] = useState([])
    const [loading, setLoading] = useState(false)
    const [len, setlen] = useState(0)

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };




    const getmaterials = async (subject) => {
        setLoading(true)
        try {
            const tag = subject;
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/pages/plamaterials`, { tag });

            if (res.data.success) {
                setMaterials(res.data.materials)
                console.log(res.data.materials)

            }
            else {
                console.log("Materials not found")
            }

        } catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }


    const getAll = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/pages/placement-materials`)
            if (res) {
                setMaterials(res.data.data)
                setlen(res.data.total)
                console.log(res)
            }
            else {
                toast.error("Please try again!")
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAll();
    }, [])


    const carouselImages = [
        "https://media.geeksforgeeks.org/wp-content/uploads/20230919110724/Aptitute-Test.webp",
        "https://builtin.com/sites/www.builtin.com/files/styles/ckeditor_optimize/public/inline-images/Data%20Science%201600x800.jpg",
        "https://i0.wp.com/i.postimg.cc/sX7gYM90/dbms.png?resize=524%2C336&ssl=1",
        "https://media.licdn.com/dms/image/D4D12AQF5GiLA7L7gWA/article-cover_image-shrink_720_1280/0/1684559947493?e=1721260800&v=beta&t=6v6ZT7FPg3ayNpGNNv2JQMby7QCgenj23Q24XtZ8n9s",
        "https://miro.medium.com/v2/resize:fit:1400/0*cl7fc6pt1MHjIF4K.png",
        "https://images.shiksha.com/mediadata/images/articles/1582804864phpq24JjK.jpeg",
        "https://emeritus.org/in/wp-content/uploads/sites/3/2023/01/What-is-machine-learning-Definition-types-1024x515.jpg.webp",
        "https://images.datacamp.com/image/upload/v1646560927/shutterstock_1329434768_bf9003ea47.jpg",
        "https://res.cloudinary.com/people-matters/image/upload/q_auto,f_auto/v1691982397/1691982395.jpg"
    ];


    const isMobile = isMobileDevice();






    return (


        <>

            <div className={`${styles.mobile}`}>
                <Nav />
                <h5 className='text-center my-3'>No of Placement Materials : {len}</h5>
                <div className="container my-1">
                    <Carousel autoplay>
                        {carouselImages.map(image => (
                            <div className='text-center'>
                                <img src={image} alt="" style={{ width: "99%", minHeight: "200px", maxHeight: "350px" }} />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className='text-center m-3'>
                    <a className="btn  btn-primary" href='https://github.com/iTKonnects-connectwithtechnology/BecStudoMaterials/tree/main/PlacementMaterials' target='_blank'> View all materials</a>

                </div>
                <div className="note">
                    <p className='p-1'>***We prefer watching this page in desktop or pc, for better experince and to get all materials***</p>
                </div>
                <Footer />
            </div>

            <div className={`${styles.desktop}`}>

                <Nav />
                <div className="container d-flex" style={{ minHeight: "50vh" }}>
                    <div className="col-2 m-2 card" style={{ display: "flex", flexDirection: "column" }}>
                        <button className="btn btn-success m-1 p-1 text-center" onClick={() => { setdisplay(false); setsub(""); }}>All categories</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("all"); getAll(); }}>All</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("companies"); getmaterials("companies"); }}>Companies</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("android"); getmaterials("android"); }}>Android App</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("aptitude"); getmaterials("aptitude"); }}>Aptitude</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("ds"); getmaterials("ds"); }}>Data science</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("dbms"); getmaterials("dbms"); }}>DBMS</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("dsa"); getmaterials("dsa"); }}>DSA</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("fsd"); getmaterials("fsd"); }}>Full stack web</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("gate"); getmaterials("gate"); }}>GATE</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("ml"); getmaterials("ml"); }}>Machine Learning</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("coding"); getmaterials("coding"); }}>Coding</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("python"); getmaterials("python"); }}>Python</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("sd"); getmaterials("sd"); }}>System Design</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("resources"); getmaterials("resources"); }}>Resources</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("interview"); getmaterials("interview"); }}>Interviews</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("tips"); getmaterials("tips"); }}>Tips</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("projects"); getmaterials("projects"); }}>Projects</button>
                        <button className="btn btn-primary btn-sm mx-3 my-1 p-1" onClick={() => { setdisplay(true); setsub("startups"); getmaterials("startups"); }}>Startups</button>

                    </div>
                    <div className="col-9 m-2" style={{ display: "flex", flexDirection: "column" }}>
                        {
                            sub ?
                                <>
                                    <h1 className='text-center'>{sub.toUpperCase()} : {materials.length}</h1>
                                    <hr />
                                    {
                                        loading && <Spinner />
                                    }
                                    <div className="container" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                        {
                                            !loading && materials.length ? materials.map((m, index) => (
                                                <div className="card" style={{ width: "18rem" }}>
                                                    <div className="card-header bg-warning">
                                                        {m.title.slice(0,30)+"..."}
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{m.des.slice(0,20)+"..."}</h5>
                                                        <p className='my-1'>tags : <span className='bg-success text-white' style={{ borderRadius: "5px", paddingRight: "5px", paddingLeft: "5px" }}>{m.tag.join(', ')}</span></p>
                                                    </div>
                                                    <div className="footer">
                                                        <a href={m.link} target='_blank' className="btn btn-sm m-2 btn-primary">View pdf</a>
                                                        <a href={m.dlink} className="btn btn-sm m-2 btn-primary">Download pdf</a>
                                                    </div>
                                                </div>
                                            ))

                                                :
                                                <><h1 className='card text-center'>No materials available until</h1></>
                                        }


                                    </div>

                                </> :
                                <>
                                    <h1 className='text-center m-2'>No of Materials : {len}</h1>
                                    <div className="container m-2">
                                        <Carousel autoplay>
                                            {carouselImages.map(image => (
                                                <div>
                                                    <img src={image} alt="" style={{ width: "90%", height: "350px" }} />
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </>
                        }
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default PlacementMaterials;
