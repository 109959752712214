import React, { useState } from "react";
// import Header from "../../components/layouts/Header";
import Footer from "../../components/Reusable/js/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
// import Navbar from "../../components/UIComponents/Navbar";
import Nav from "../../components/Reusable/js/Nav";
import styles from "./css/Register.module.css"

export const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [answer, setAnswer] = useState("");
  const [regno, setRegno] = useState("")

  const navigate = useNavigate();

  const handleSubmit = async (e) => {

    e.preventDefault();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/register`,
        {
          name,
          email,
          password,
          phone,
          answer,
          regno

        }
      );

      if (res.data.success) {
        toast.success(res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error in registration!");
    }

  };



  return (
    <>
      <Nav />
      <Toaster />
      <div className={`${styles.full}`}>

        <div className="card">
          <div className="register my-2" style={{ display: "flex", flexDirection: "column" }}>
            <h1 className="my-2 text-center">Register user</h1>
            <form className="text-center m-3" onSubmit={handleSubmit}>
              <div className={`${styles.mb3}`}>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter Full Name"
                />
              </div>
              <div className={`${styles.mb3}`}>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter Email"
                  required
                />
              </div>

              <div className={`${styles.mb3}`}>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter password"
                  required
                />
              </div>

              <div className={`${styles.mb3}`}>
                <input
                  type="text"
                  className="form-control"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="Enter phone"
                  required
                />
              </div>


              <div className={`${styles.mb3}`}>
                <input
                  type="text"
                  className="form-control"
                  value={answer}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                  placeholder="Enter secret code"
                  required
                />
              </div>
              <p className="m-1"><i class="fa-solid fa-circle-exclamation mx-1"></i>This will be used to recover your account!</p>

              <div className={`${styles.mb3}`}>
                <input
                  type="text"
                  className="form-control"
                  value={regno}
                  onChange={(e) => {
                    setRegno(e.target.value);
                  }}
                  placeholder="Enter reg no"
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};
