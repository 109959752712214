import { Routes, Route } from 'react-router-dom';
import Homepage from './pages/default/Homepage';

import { Pagenotfound } from "./pages/default/Pagenotfound"
import "./App.css"


import { Toaster } from 'react-hot-toast';
import { Login } from './pages/Auth/Login';
import { Register } from './pages/Auth/Register';
import AuthState from './context/AuthState';
import Dashboard from './pages/users/Loggedin/js/Dashboard';
import PrivateRoute from './components/routes/Private';
import ForgotPassword from './pages/Auth/ForgotPassword';
import AdminRoute from './components/routes/AdminRoute';
import Profile from './pages/users/Loggedin/js/Profile';

import AddPaper from './pages/admin/AddPaper';
import PlacementMaterials from './Features/PlacementMaterials/PlacementMaterials'
import Users from './pages/admin/Users';
import AddTechie from './pages/admin/AddTechie';
import Techies from "./Features/Techies/Techies"

import AddCompany from './pages/admin/AddCompany';
import AddStudentPlacement from './pages/admin/AddStudentPlacement';

import AdminProfile from './pages/admin/AdminProfile';
import Y20results from './Features/Y20Results/Y20results';
import Y20Placements from './Features/Y20Placements/Y20Placements';
import { Y21results } from './Features/Y20Results/Y20results';
import PrivacyPolicy from './pages/default/PrivacyPolicy';
import Test from './components/Test/js/Test';
import ContactUs from './pages/users/Loggedin/js/ContactUs';
import Materials from './Features/Materials/Materials';
import PreviousPapers from './Features/PreviousPapers/PreviousPapers';





function App() {
  return (
    <>
      <AuthState>
        <Toaster />

        <Routes>
          <Route path='/' element={<Homepage />} />



          {/* //private routes */}

          <Route path='/user' element={<PrivateRoute />}>
            <Route path='dashboard/profile' element={<Profile />} />
            <Route path='restaurants' element={<Profile />} />
          </Route>


          {/* user dashboard routes */}

          <Route path='/dashboard' element={<PrivateRoute />}>
            <Route path='user/contactus' element={<ContactUs />} />
            <Route path='user' element={<Dashboard />} />
            <Route path='user/profile' element={<Profile />} />
            <Route path='user/materials' element={<Materials />} />
            <Route path='user/y20placements' element={<Y20Placements />} />


          </Route>

          <Route path='/dashboard' element={<AdminRoute />}>
            <Route path='admin/profile' element={<Profile />} />
            <Route path='admin/add-paper' element={<AddPaper />} />
            <Route path='admin/users' element={<Users />} />
            <Route path='admin/add-techie' element={<AddTechie />} />
            <Route path='admin/add-company' element={<AddCompany />} />
            <Route path='admin/operations' element={<AdminProfile />} />
            <Route path='admin/add-student-placement' element={<AddStudentPlacement />} />
          </Route>

          {/* public routes for all can use */}


          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/previous-papers' element={<PreviousPapers />} />
          <Route path='/placement-materials' element={<PlacementMaterials />} />
          <Route path='/techies' element={<Techies />} />
          <Route path='/results/y20' element={<Y20results />} />
          <Route path='/results/y21' element={<Y21results />} />
          <Route path='/materials' element={<Materials />} />



          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/*' element={<Pagenotfound />} />


          <Route path='/test' element={<Test />} />



        </Routes>

      </AuthState>
    </>


  );
}

export default App;
