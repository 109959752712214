import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './TestNav.css'

const TestNav = () => {
    const [clicked, setClicked] = useState(false)



    const handleclick = () => {
        setClicked(!clicked)
    };

    const Menuitem = [
        {
            title: "Home",
            cname: "nav-links",
            icon: "fa-solid fa-house",
            url: "/",
        },
        {
            title: "About",
            cname: "nav-links",
            icon: "fa-solid fa-circle-info",
            url: "/about",
        },
        {
            title: "Service",
            cname: "nav-links",
            icon: "fa-solid fa-briefcase",
            url: "/service",
        },
        {
            title: "Contact",
            cname: "nav-links",
            icon: "fa-solid fa-briefcase",
            url: "/contact",
        },
        {
            title: "SignUp",
            cname: "nav-links-mobile",
            url: "/signup",
        },
    ];



    return (
        <nav className="Navbaritems">
            <h1 className="navbar-logo">BEC Studo</h1>
            <div className="menu-icons" onClick={handleclick}>
                <i className={clicked ? "fas fa-times" : "fas fa-bars"} />
            </div>
            <ul
                className={clicked ? "navbar-menu active" : "navbar-menu"}
            >
                {Menuitem.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.cname} to={item.url} >
                                <i className={item.icon}></i>
                                {item.title}
                            </Link>
                        </li>
                    );
                })}
                <button className="button">SignUp</button>
            </ul>
        </nav>
    );

}

export default TestNav