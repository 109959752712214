import React from 'react'
import Nav from '../../components/Reusable/js/Nav'
import Footer from '../../components/Reusable/js/Footer'

const Materials = () => {
    const depts = [
        {
            name: "Information Technology",
            code: "IT",
            link: "https://drive.google.com/drive/folders/122_59EClXhB6khv5h5LRoWpG24tP-Tj1"
        },
        {
            name: "Computer Science",
            code: "CSE",
            link: "https://drive.google.com/drive/folders/122_59EClXhB6khv5h5LRoWpG24tP-Tj1"
        },
        {
            name: "Electronics and communications Engineering",
            code: "ECE",
            link: "https://drive.google.com/drive/folders/1pXhbuWDqy4nQ6o9zk2sRrL3v3df2BSqG"
        },

    ]
    return (
        <>
            <Nav />
            <div className="container text-center">
                <h1>All Departments</h1>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                    {
                        depts.map(dept => (
                            <>
                                <div class="card" style={{ width: "18rem" }}>
                                    <div class="card-header">
                                        <h2>{dept.code}</h2>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-title">{dept.name}</p>
                                    </div>
                                    <div>
                                        <a href={dept.link} target='_blank' class="btn btn-primary">view folder</a>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Materials