import React from 'react'
import Nav from '../../components/Reusable/js/Nav'
import Footer from '../../components/Reusable/js/Footer';



const Y20results = () => {

    const mainbtn = {
        minWidth: "100px"
    };
    return (
        <>
            <Nav />
            <div className="container p-2" style={{ minHeight: "50vh" }}>
                <h1 className="text-center">
                    Y20 Results and links for each semester
                </h1>
                <div className="mainpages" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <a href="http://becbapatla.ac.in:8080/STUDENTINFO/index_r20.html" target='_blank' className="btn btn-primary m-2" style={mainbtn}>R20</a>
                    <a href="http://becbapatla.ac.in:8080/STUDENTINFO/index_r18.html" target='_blank' className="btn btn-primary m-2" style={mainbtn}>R18</a>
                    <a href="http://becbapatla.ac.in:8080/STUDENTINFO/index.html" target='_blank' className="btn btn-primary m-2" style={mainbtn}>R14/R10</a>
                    <a href="http://becbapatla.ac.in/examination-results/" target='_blank' className="btn btn-primary m-2" style={mainbtn}>Results page</a>
                </div>
                <div className="total p-2" style={{ border: "solid 1px black", display: "flex", flexDirection: "column" }}>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>IT</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/11_r20_reg_jul21_3t4l.html" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/12_r20_reg_oct21_6t3l.html" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/21_r20_reg_mar22_6t3l.html" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/22_r20_reg_aug22_4t3l.html" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/31_r20_reg_feb23_5t3l.html" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/32_r20_reg_aug23_5t3l.html" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="http://www.becbapatla.ac.in:8080/collegeresults/41_r20_reg_jan24_5t2l.html" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="http://becbapatla.ac.in:8080/collegeresults/42_r20_reg_apr24_0t1l.html" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>CSE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>CB</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>DS</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>ECE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>EEE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>EIE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>CE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>ME</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}



export const Y21results = () => {

    const mainbtn = {
        minWidth: "100px"
    };
    return (
        <>
            <Nav />
            <div className="container p-2" style={{ minHeight: "50vh" }}>
                <h1 className="text-center">
                    Y21 Results and links for each semester
                </h1>
                <hr />
                <div className="mainpages" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <a href="http://becbapatla.ac.in:8080/STUDENTINFO/index_r20.html" target='_blank' className="btn btn-primary m-2" style={mainbtn}>R20</a>
                    <a href="http://becbapatla.ac.in:8080/STUDENTINFO/index_r18.html" target='_blank' className="btn btn-primary m-2" style={mainbtn}>R18</a>
                    <a href="http://becbapatla.ac.in:8080/STUDENTINFO/index.html" target='_blank' className="btn btn-primary m-2" style={mainbtn}>R14/R10</a>
                    <a href="http://becbapatla.ac.in/examination-results/" target='_blank' className="btn btn-primary m-2" style={mainbtn}>Results page</a>
                </div>
                <div className="total p-2" style={{ border: "solid 1px black", display: "flex", flexDirection: "column" }}>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>IT</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>CSE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>CB</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>DS</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>ECE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>EEE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>EIE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>CE</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                    <div className="container m-2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <a href="" target='_blank' className="btn btn-success" style={mainbtn}>ME</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>1.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>2.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>3.2</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.1</a>
                        <a href="" target='_blank' className="btn btn-warning" style={mainbtn}>4.2</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Y20results;