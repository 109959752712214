import React from 'react';
import styles from  './css/PrivacyPolicy.module.css';
import Nav from '../../components/Reusable/js/Nav';
import Footer from '../../components/Reusable/js/Footer';

const PrivacyPolicy = () => {
  return (
    <>
    <Nav/>
    <div className="container">
    <div className={`${styles.privacypolicy}`}>
      <h1>Privacy Policy for BEC Studo</h1>
      <p><strong>Effective Date:</strong> 20 june 2024</p>
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to BEC Studo, an online platform for the students of Bapatla Engineering College. This Privacy Policy explains how we collect, use, and protect your personal information when you use our services. By using BEC Studo, you agree to the practices described in this policy.</p>
      </section>
      <section>
        <h2>2. Information We Collect</h2>
        <p>We collect the following types of personal information through forms filled out by students on our website:</p>
        <ul>
          <li>Basic details: Name, email address</li>
          <li>Educational details: B.Tech educational information</li>
        </ul>
        <p>We do not collect any payment information.</p>
      </section>
      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use the collected information to:</p>
        <ul>
          <li>Provide and improve our services</li>
          <li>Mentor students for career guidance</li>
          <li>Offer better services related to food, accommodation, academics, previous papers, results, and alumni connections</li>
        </ul>
      </section>
      <section>
        <h2>4. Data Sharing</h2>
        <p>We do not share your personal information with third parties.</p>
      </section>
      <section>
        <h2>5. Data Security</h2>
        <p>We are committed to protecting your personal information. We use hashed passwords and secure databases with authentication to ensure that your data is safe. Access to the website is restricted to authenticated users.</p>
      </section>
      <section>
        <h2>6. User Rights</h2>
        <p>You have the following rights regarding your personal information:</p>
        <ul>
          <li>Correction: You can request to correct any inaccuracies in your personal information.</li>
          <li>Deletion: You can request the deletion of your personal information.</li>
        </ul>
      </section>
      <section>
        <h2>7. Contact Information</h2>
        <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us using the details provided in the footer of our website.</p>
      </section>
      <section>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
      </section>
      <footer>
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <address>
          itkonnects@gmail.com <br />
        </address>
      </footer>
    </div>
    </div>
    <hr />
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;
