import React, { useEffect, useState } from 'react'

import Footer from '../../components/Reusable/js/Footer'
import AdminMenu from '../../components/admin/js/AdminMenu'
import Nav from '../../components/Reusable/js/Nav'
import axios from 'axios'
import toast from 'react-hot-toast'

const Users = () => {

    const [users, setusers] = useState([])

    const getusers = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/users/getusers`)
            setusers(res.data.users)
            toast.success("done")
        } catch (error) {
            toast.success("not done")
            console.log(error)
        }
    }

    useEffect(() => {
        getusers()
    }, [])


    return (
        <>
            <Nav />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: "nowrap" }}>
                <div style={{ width: "25%", margin: "10px" }}>
                    <AdminMenu />
                </div>
                <div className="column" style={{ display: "flex",width:"70%", flexDirection: "column", justifyContent: "space-around", flexWrap: "nowrap" }}>
                    <h1 className='text-center'>users list</h1>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap" }}>

                        {users.map(user => (
                            <div className="card" key={user._id} style={{ width: "25rem" }}>
                                <p className='text-danger' style={{fontWeight:"600",margin:"0",padding:"0"}}>username: {user.name}</p>
                                <p style={{margin:"0",padding:"0"}}>email: {user.email}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Users