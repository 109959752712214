import React from 'react'

const LatestNews = () => {

    const news = [
        {
            title: "Exams Postponed for final years",
            des: "For not paying fee, students are stopped from attending final viva",
            date: "16 may 12:53"
        }, 
        {
            title: "Elections campaign at college",
            des: "College is under govt handover for ballette box security",
            date: "26 apr 22:53"
        }, 
        {
            title: "Summer holidays till june 6",
            des: "Holidays were declared for students till June 6",
            date: "26 apr 22:53"
        },
        {
            title: "NBA",
            des: "Accredited for tier 1 college for cse,civil",
            date: "26 apr 22:53"
        },
        {
            title: "Postponed for final years",
            des: "Final date for final years has been postponed, for not paying fees",
            date: "26 apr 22:53"
        },


    ]
    return (
        <>

            {news.map(item => (
                <div className='card m-2'>
                    <h6 style={{ margin: "1px", padding: "1px" }}>{item.title}</h6>
                    <p style={{ margin: "0", padding: "0" }}>{item.des}</p>
                    <span style={{ fontSize: "0.8rem", margin: "0", padding: "0" }}>{item.date}</span>

                </div>
            ))}

        </>
    )
}

export default LatestNews