import React, { useContext, useState } from 'react';
import Nav from '../../../../components/Reusable/js/Nav';
import Footer from '../../../../components/Reusable/js/Footer';
import styles from "../css/ContactUs.module.css";
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../../context/AuthContext';

const ContactUs = () => {

    const [auth] = useContext(AuthContext);

    const [title, setTitle] = useState("");
    const [des, setDes] = useState("");
    const [mis, setMis] = useState("");
    
    const id=(auth?.user._id)
    const [sentBy, setSentBy] = useState(id);
    
    console.log(id);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const datar = { title, des, mis, sentBy };
            console.log(datar);
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/pages/contactus`, datar);
            if (res.data) {
                console.log("sent success");
                toast.success("Sent successfully!");
                navigate("/");
            } else {
                toast.error("Some error occurred");
            }
        } catch (error) {
            toast.error("Try again");
            console.log(error);
        }
    };

    return (
        <>
            <Nav />
            <h1 className="text-center mt-3">Contact Us</h1>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <form onSubmit={handleSubmit} className={`${styles.form} mb-3`}>
                    
                    <div className="mb-3">
                        <input
                            placeholder='Issue is regarding?'
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control"
                            id="exampleInputtext1"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            placeholder='Details...'
                            type="text"
                            value={des}
                            onChange={(e) => setDes(e.target.value)}
                            className="form-control"
                            id="exampleInputtext2"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            placeholder='You need anything else?'
                            type="text"
                            value={mis}
                            onChange={(e) => setMis(e.target.value)}
                            className="form-control"
                            id="exampleInputtext3"
                        />
                    </div>
                    {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    <button type="submit" className="m-1 btn btn-sm btn-primary">Submit</button>
                </form>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
