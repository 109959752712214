import React from 'react'
import Nav from '../../Reusable/js/Nav'
import Footer from "../../Reusable/js/Footer"
import styles from "../../Test/css/Test.module.css"
import TestNav from '../TestNavbar/TestNav'

const Test = () => {
  return (
    <>
   
    <TestNav/>
    <div style={{minHeight:"300px"}}>

    </div>
    <Footer/>
    </>
  )
}

export default Test