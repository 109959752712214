import React, { useState } from "react";

import Footer from "../../components/Reusable/js/Footer";
import axios from "axios"
import styles from "./Login.module.css"
import { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import AuthContext from "../../context/AuthContext";

import Spinner from "../../components/Reusable/js/Spinner";

import Nav from "../../components/Reusable/js/Nav";


export const Login = () => {




  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const location = useLocation();

  const [auth, setAuth] = useContext(AuthContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  if (auth.user) {
    navigate("/")
  }



  const handlesubmit = async (e) => {
    setLoading(true)

    if (loading) {
      return (
        <>
          <Nav />
          <Spinner />
          <Footer />
        </>
      )
    }


    e.preventDefault();

    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/login`, { email, password });

      if (res && res.data.success) {
        toast.success(res.data.message)
        setLoading(false)
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token
        })

        localStorage.setItem("eG3FbKp9tWnR2qP8sYhE1", JSON.stringify(res.data))
        navigate(location.state || "/")

      }
      else {
        toast.error(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error("Please check credentials")
    }
  }

  return (
    <>

      <Nav />

      <div className={`${styles.main}`}>
        <div className={`${styles.main2} card`}>
          <div className="heading">
            <h1 className="text-center">Login User</h1>
            <form className="text-center m-3" onSubmit={handlesubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className={`${styles.inputl} form-control`}
                  value={email}
                  onChange={(e) => { setemail(e.target.value); }}

                  placeholder="Enter Email"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => { setpassword(e.target.value); }}
                  placeholder="Enter Password"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary btn-sm">
                Login
              </button>
              <div className="m-3">
                <a className="text-danger" href="#" onClick={() => { navigate('/forgot-password'); }} style={{ textDecoration: "none" }}>Forgot Password</a>

              </div>
              <div>
                <a className="m-2" href="#" onClick={() => { navigate('/forgot-password'); }} style={{ textDecoration: "none" }}>No account yet? Register</a>

              </div>
            </form>
          </div>
        </div>
      </div>


      <Footer />
    </>

  );
};
