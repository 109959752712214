import React, { useState } from 'react'
import Nav from '../../components/Reusable/js/Nav'
import Footer from '../../components/Reusable/js/Footer'
import AdminMenu from '../../components/admin/js/AdminMenu'
import { Checkbox, Radio, Row, Col } from 'antd'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const AddPaper = () => {

    const [dept, setDept] = useState("")
    const [sub, setsub] = useState("")
    const [code, setcode] = useState("")
    const [link, setlink] = useState("")
    const [des, setdes] = useState("")
    const [imglink, setimglink] = useState("")
   

    const deptsarray = [
        'CS','IT','AI','ML','CB','DS', 'ECE', 'EIE', 'EEE', 'CIVIL',
        'MECH', 'OE','HONORS','firstyear','PHY','CHE','ENG'];

    const onChange = (checkedValues) => {
        setdepts(checkedValues)
        console.log('checked = ', checkedValues);
    };


    const navigate = useNavigate()

    
    const [depts, setdepts] = useState([])


    const handlesubmit = async (e) => {
        e.preventDefault()

        const datar = { sub, code, link, dept, des, imglink, depts }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/subjects/add-qpapersub`, datar)
            console.log(res)

            if (res?.data?.success) {
                toast.success("success")
                navigate("/dashboard/admin/add-paper")
                setDept("")
                setlink("")
                setcode("")
                setsub("")
                setdes("")
                setdepts("")
                setimglink("")
                
            }



        } catch (error) {
            console.log("failed to post", error)
            toast.error("failed to post")
        }


    }
    return (
        <>
            <Nav />

            <div className="container" style={{ display: "flex", flexDirection: "row" }}>
                <div className="adminmenu m-2" style={{ width: "25%" }}>
                    <AdminMenu />
                </div>
                <div style={{ width: "65%" }} className='m-2'>
                    <h2 className='m-2 text-center'>Add paper</h2>
                    <form>
                        <div className="form-group m-2">
                            <input type="text" value={sub} onChange={(e) => setsub(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter subject name" />
                        </div>

                        <div className="form-group m-2">
                            <input type="text" value={code} onChange={(e) => setcode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="code" />
                        </div>
                        <div className="form-group m-2">
                            <input type="text" value={link} onChange={(e) => setlink(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="link" />
                        </div>
                        <div className="form-group m-2">
                            <input type="text" value={des} onChange={(e) => setdes(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="des" />
                        </div>
                        <div className="form-group m-2">
                            <input type="text" value={imglink} onChange={(e) => setimglink(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="image link" />
                        </div>
                        <div className="form-group m-2">
                            <input type="text" value={dept} onChange={(e) => setDept(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="departments mainly belogn to" />
                        </div>
                       

                        <div className="m-2">

                        <Checkbox.Group
                            style={{
                                width: '100%',
                            }}
                            onChange={onChange}
                        >
                            <Row style={{display:"flex",flexDirection:"row",flexWrap:'wrap'}}>
                                {deptsarray.map((tag, index) => (
                                    <Col span={8} key={index}>
                                        <h3><Checkbox value={tag}>{tag}</Checkbox></h3>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>

                    </div>

                        

                        <button type="submit" onClick={handlesubmit} className="btn btn-primary">Submit</button>
                    </form>

                </div>
            </div>


            <Footer />
        </>
    )
}

export default AddPaper