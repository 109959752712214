import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { NavLink, Link } from 'react-router-dom';
import styles from "../PreviousPapers/PreviousPapers.module.css"
import Footer from '../../components/Reusable/js/Footer';
import Nav from '../../components/Reusable/js/Nav';
import Spinner, { SmallSpinner } from '../../components/Reusable/js/Spinner';

const PreviousPapers = () => {
  const [loading, setLoading] = useState(false)
  const [papers, setPapers] = useState([])
  const [count, setCount] = useState(0)

  const [initial, setinitial] = useState(0)

  const [selectedDept, setSelectedDept] = useState("ALL")
  const [totalcount, setTotalcount] = useState(0)

  const [clicked,setclicked]=useState(false)


  const getPapers = async () => {
    setLoading(true)
    setinitial(1)
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/subjects/get-papers`);
      if (res.data.success) {
        setPapers(res.data.papers);
        setCount(res.data.papers.length)
        setTotalcount(res.data.papers.length)

      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to get papers");
    }
    finally {
      setLoading(false)
    }
  };

  const applyFilters = async (deptname) => {
    setLoading(true)
    try {

      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/subjects/get-papers/${deptname}`);
      console.log(res);
      if (res.data) {
        console.log(res);
        setPapers(res.data.papers);
        setCount(res.data.total ? res.data.total : 0)
        console.log("entered", deptname);
      }
      else {
        console.log("data error");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to get papers");
    }
    finally {
      setLoading(false)
    }
  }

  if (initial == 0) {
    getPapers();
  }



  // const depts = [
  //     { selected: false, id: 1, name: "COMPUTERS" },
  //     { selected: false, id: 2, name: "ECE" },
  //     { selected: false, id: 3, name: "EEE" },
  //     { selected: false, id: 4, name: "EIE" },
  //     { selected: false, id: 5, name: "CIVIL" },
  //     { selected: false, id: 6, name: "MECH" },
  //     { selected: false, id: 7, name: "First Year" }
  // ];

  const depts = [
    { id: 0, name: 'ALL', value: 'ALL' },
    { id: 1, name: 'COMPUTERS', value: 'IT' },
    { id: 2, name: 'ECE', value: 'ECE' },
    { id: 3, name: 'EEE', value: 'EEE' },
    { id: 4, name: 'EIE', value: 'EIE' },
    { id: 5, name: 'CIVIL', value: 'CIVIL' },
    { id: 6, name: 'MECH', value: 'MECH' },
    { id: 7, name: 'HONORS', value: 'HONORS' },
    { id: 8, name: 'OE', value: 'OE' },
    { id: 9, name: 'First Year', value: 'firstyear' },
    { id: 10, name: 'PHY', value: 'PHY' },
    { id: 11, name: 'CHE', value: 'CHE' },
    { id: 12, name: 'ENG', value: 'ENG' },
  ];


  // const [locations, setLocations] = useState(depts);


  // const handleChange = (id) => {
  //     setLocations(locations.map(location => {
  //         if (location.id === id) {
  //             return { ...location, selected: !location.selected };
  //         }
  //         return location;
  //     }));
  // };

  const handleChange = (id) => {
    setSelectedDept(id); // Update the selected department ID
  };


  // const selectedLocations = locations
  //     .filter(location => location.selected)
  //     .map(location => location.name);




  const BreadCrumb = () => {
    return (
      <>
        <nav aria-label="breadcrumb" className='m-1'>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/previous-papers">PreviousPapers</Link></li>
            <li class="breadcrumb-item active" aria-current="page">{selectedDept ? selectedDept : "ALL"}</li>
          </ol>
        </nav>

      </>
    )
  }


  // {`${styles.leftmenu}`}




  return (
    <>
      <Nav />
      <BreadCrumb />
      <div className={`container ${styles.full}`}>

        <div className={`${styles.middle}`}>

          {
            loading ? (
              <>
                <Spinner />
              </>

            ) : (
              papers.length ? (papers.map(paper => (
                <>
                  <div class={`${styles.item} card`}>
                    <img class="card-img-top" src={paper.imglink} alt="Card image cap" style={{ height: "20vh", objectFit: "cover" }} />
                    <div class="card-body">
                      <h6 class="card-title">{paper.sub}</h6>
                      <p>{paper.code} : {paper.des}</p>
                      <p>{paper.depts.join(", ")}</p>
                    </div>
                    <a href={paper.link} target='_blank' class="btn btn-sm btn-primary">Open folder</a>
                  </div>
                </>
              ))) : (
                <>
                  <h2>
                    No Question papers found!
                  </h2>
                </>
              )
            )
          }
        </div>
        <div className={`${styles.right} card`}>

          <div className="form-group m-1">
            <h3 style={{ display: "flex", flexDirection: "row" }}>Total: {loading && <SmallSpinner />} {!loading && count}/{totalcount}</h3>
            <label className='fw fw-bolder' htmlFor="filterLocation">Departments</label>
            {depts.map(dept => (
              <>
                <div class="form-check">
                  <label class="form-check-label" for={dept.id}>
                    <input type="radio" class="form-check-input" id={dept.id} name="optradio" value={dept.name} onChange={() => { setSelectedDept(dept.name); applyFilters(dept.value); }} onClick={() => { setSelectedDept(dept.name); applyFilters(dept.value); }} />{dept.name}
                  </label>
                </div>

              </>
              // <div key={dept.id}>
              //     <input
              //         type="radio"
              //         className="form-check-input mx-1"
              //         style={{ border: 'solid 1px black' }}
              //         onClick={() => {
              //             applyFilters(dept.name)
              //         }}

              //     />
              //     <label className="form-check-label" htmlFor={`dept${dept.id}`}>
              //         {dept.name}
              //     </label>
              // </div>
            ))}
            {/* <div>
                          <h3>Selected Departments</h3>
                          <ul>
                              <li>{selectedDept}</li>
                              {setSelectedDept.map(name => (
                                  <li key={name}>{name}</li>
                              ))}
                          </ul>
                      </div> */}
          </div>




          <a className="btn btn-sm btn-danger m-1" onClick={() => { getPapers(); setLoading(true); setSelectedDept("ALL") }}>clear</a>


        </div>

        <div className="text-center">
          <button className={`${styles.filter} btn btn-sm  mx-1`} onClick={()=>{setclicked(!clicked)}}><i class="mx-1 fa-solid fa-filter btn btn-sm btn-outline-dark"></i>Dept Filters &gt; {selectedDept} : {count}/{totalcount}</button>
            {
              clicked && (<div className={`${styles.mobileright} card`}>

                <div className="form-group m-1">
                  <h3 style={{ display: "flex", flexDirection: "row" }}>Total: {loading && <SmallSpinner />} {!loading && count}/{totalcount}</h3>
                  <label className='fw fw-bolder' htmlFor="filterLocation">Departments</label>
                  {
                    depts.map(dept => (
                      <div className={`${styles.micons}`}>
                        <div class="form-check">
                          <label class="form-check-label" for={dept.id}>
                            <input type="radio" 
                            class="form-check-input" 
                            id={dept.id} name="optradio" 
                            value={dept.name} 
                            onChange={() => { 
                              setSelectedDept(dept.name); 
                              applyFilters(dept.value);
                              setclicked(!clicked) }} onClick={() => { setSelectedDept(dept.name); applyFilters(dept.value); setclicked(!clicked)}} />{dept.name}
                          </label>
                        </div>
      
                      </div>
                      // <div key={dept.id}>
                      //     <input
                      //         type="radio"
                      //         className="form-check-input mx-1"
                      //         style={{ border: 'solid 1px black' }}
                      //         onClick={() => {
                      //             applyFilters(dept.name)
                      //         }}
      
                      //     />
                      //     <label className="form-check-label" htmlFor={`dept${dept.id}`}>
                      //         {dept.name}
                      //     </label>
                      // </div>
                    ))
                  }
                  {/* <div>
                                <h3>Selected Departments</h3>
                                <ul>
                                    <li>{selectedDept}</li>
                                    {setSelectedDept.map(name => (
                                        <li key={name}>{name}</li>
                                    ))}
                                </ul>
                            </div> */}
                </div>
      
      
      
      
                <a className="btn btn-sm btn-danger m-1" onClick={() => { getPapers(); setLoading(true); setSelectedDept("ALL") }}>clear</a>
      
      
              </div>)
            }
        </div>

        

      </div >
      <Footer />
    </>
  )
};

export default PreviousPapers;
