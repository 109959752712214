import React from 'react'
import Header from '../../../../components/Test/js/Header'
import Footer from '../../../../components/Reusable/js/Footer'
import UserMenu from '../../../../components/user/js/UserMenu'
import Nav from '../../../../components/Reusable/js/Nav'



const Dashboard = () => {

    return (
        <>
            <Nav />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 m-3">
                        <UserMenu />
                    </div>
                    <div className="col-md-8">
                        <div className='text-center'>
                            <h1 className='text-center'>user Dash board</h1>
                        </div>
                    </div>
                </div>


            </div>

            <Footer />

        </>
    )
}

export default Dashboard