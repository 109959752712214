import React, { useContext, useState } from "react";
import Footer from "../../../../components/Reusable/js/Footer";
import AuthContext from "../../../../context/AuthContext";
import AdminMenu from "../../../../components/admin/js/AdminMenu";
import toast from "react-hot-toast";
import Nav from "../../../../components/Reusable/js/Nav";

const Profile = () => {
    const [auth] = useContext(AuthContext);
    const isadmin = auth?.user?.role=="0"
    

    return (
        <>

            <Nav />
            <div style={{ flex: "wrap", display: "flex", flexDirection: "row" }} className="m-3">
                <div className="container" style={{ display: "flex", flexDirection: "row" }}>
                    <div className="col-3">
                        {isadmin && <AdminMenu />}
                    </div>
                    <div className="col-9 m-2" style={{ minHeight: "30vh" }}>

                        <p>{auth?.user.name}</p>
                        {/* <p>{auth?.user.token}</p> */}
                        <p>{auth?.user.regno}</p>
                        <p>{auth?.user.email}</p>
                        <p>{auth?.user.phone}</p>
                        <p>{auth?.user.answer}</p>
                        {/* <p>{auth?.user.role}</p> */}

                    </div>
                </div>
            </div>




            <Footer />
        </>
    );
};

export default Profile;